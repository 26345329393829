// var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
var flyoutNav = true;
// var fullscreenNav = true;
// var homeQuickGivingPreFooter = false;
// var countUpStats = false;
// var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
var feedTitleLinks = ['/Pages/Category/whats-on', '/']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// $(".homeFeed.homeFeedevents").insertAfter(".homeIntroWrapper");
//Homepage feeds
// $(".homeFeedBox1").insertAfter(".homeIntroWrapper");
// $(".homeFeedBox2").insertAfter(".homeFeedBox1");

//  Card - No banner
$(".homeFeatures .homeFeature:not(:has([class*='image-link']))").addClass("homeFeatureNoBanner");
$(".feedItem:not(:has([class*='image-link']))").addClass("feedItemNoBanner");
$(".listedPost:not(:has([class*='image-link']))").addClass("listedPostNoBanner");

$("section.homeFeaturesWrapper").insertAfter(".homeFeedBox1");

// Newsletter
$(".footerBox[class*='ewsletter']").prependTo(".pageFooterWrapper");

// Footer Logos
$(".footerBox[class*='ogos']").insertAfter(".pageFooterWrapper");

// Featured appeal
$(".associated.associatedBox ").each(function() {
    $(this).children().not("a:first-child").wrapAll('<div class="associatedBoxDetailsWrapper" />');
});

// Card movement
$(function(){

  // define card
  var card = $(".listedPost, .feedItem, .homeFeatures .homeFeature, .associated.associatedBox");

  // Behavior
  card.on('mousemove', function (e) {
      var xAxis = e.clientX - $(this).offset().left + $(window).scrollLeft();
      var yAxis = e.clientY - $(this).offset().top + $(window).scrollTop();
      
      var yRotation = map(xAxis, 0, $(this).width(), -12, 12);
      var xRotation = map(yAxis, 0, $(this).height(), -12, 12);
  
      $(this).children().css("transform", "rotateY(" + yRotation + "deg)" + " " + "rotateX(" + - xRotation + "deg)");
  });
  
  // Effect on
  card.on('mouseenter', function () {
      $(this).children().css({
          transition: "all " + 0.3 + "s" + " linear",
      });
  });

  // Effect off
  card.on('mouseleave', function () {
      $(this).children().css({
          transition: "all " + 0.3 + "s" + " linear",
      });

      $(this).children().css("transform", "rotateY(" + 0 + "deg)" + " " + "rotateX(" + 0 + "deg)");
  });

  function map(xAxis, in_min, in_max, out_min, out_max)
  {
      return (xAxis - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
  }
});

// // Wrapping homepage feed title with link
// // Step 1: Retrieve the parent element
// var parentElement = document.querySelector('.homeFeed.homeFeedevents .feedsTitle');

// // Step 2: Create a new anchor element
// var anchorElement = document.createElement('a');

// // Step 3: Set the href attribute of the anchor element
// anchorElement.href = '/Pages/Events/Category/whats-on';

// // Step 4: Clone the parent element
// var clonedElement = parentElement.cloneNode(true);

// // Step 5: Clear the contents of the parent element
// parentElement.innerHTML = '';

// // Step 6: Append the anchor element as a child of the parent element
// parentElement.parentNode.replaceChild(anchorElement, parentElement);

// // Step 7: Append the cloned element as a child of the anchor element
// anchorElement.appendChild(clonedElement);

